import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Button from '../components/Button'
import SubscribeForm from '../components/SubscribeForm'
import { CarbonAds } from '../components/CarbonAds'

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { title, subtitle, date, sourceCode, path } = frontmatter
  const { previous, next } = pageContext

  let sourceCodeLink
  if (sourceCode) {
    sourceCodeLink = (
      <Button
        text="See on Github"
        link={sourceCode}
        linkType="external"
        className="mt-2"
      />
    )
  }

  let prevLink
  if (previous) {
    prevLink = (
      <Link to={previous.frontmatter.path} className="m-2">
        &laquo; {previous.frontmatter.title}
      </Link>
    )
  }

  let nextLink
  if (next) {
    nextLink = (
      <Link to={next.frontmatter.path} className="m-2">
        {next.frontmatter.title} &raquo;
      </Link>
    )
  }

  return (
    <Layout>
      <Seo title={title} description={subtitle} url={path} />
      <div className="container mt-2 mb-8 py-8">
        <div className="text-center mb-8 px-8">
          <h1 className="font-bold text-3xl">{title}</h1>
          <h2 className="pt-4 text-xl font-bold mb-5">{subtitle}</h2>
          <p>{date}</p>
        </div>
        <div className="flex justify-center items-center mb-8">
          <CarbonAds />
        </div>
        <div className="prose prose-indigo lg:prose-xl max-w-none">
          <div dangerouslySetInnerHTML={{ __html: html }} />
          {sourceCodeLink}
          <hr />
          <div className="flex flex-wrap sm:flex-no-wrap justify-between">
            {prevLink}
            {nextLink}
          </div>
        </div>
        <SubscribeForm />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      id
      frontmatter {
        path
        title
        subtitle
        date(formatString: "MMMM YYYY")
        sourceCode
      }
    }
  }
`
